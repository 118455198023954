import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const WBNB = new Token(ChainId.MAINNET, '0xa221B2553fd4E529d396B92CB5863d71342c2897', 18, 'WETH', 'Wrapped ETH')

const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
      3007: '0xa221B2553fd4E529d396B92CB5863d71342c2897',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  weth: {
    symbol: 'weth',
    address: {
      56: '0xa221B2553fd4E529d396B92CB5863d71342c2897',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      3007: '0xa221B2553fd4E529d396B92CB5863d71342c2897',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
}

export default tokens
