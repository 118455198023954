import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectButton = styled(Button)`
 
background-image: linear-gradient(90deg, #ff4800 0%,#ec934a 51%,#ff4800) !important;


`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)





  return (
    <ConnectButton onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')}
    </ConnectButton>
  )
}

export default ConnectWalletButton
